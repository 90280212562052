exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-kitting-out-mdx": () => import("./../../../src/pages/articles/kitting-out.mdx" /* webpackChunkName: "component---src-pages-articles-kitting-out-mdx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-battle-of-the-games-mdx": () => import("./../../../src/pages/battle-of-the-games.mdx" /* webpackChunkName: "component---src-pages-battle-of-the-games-mdx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-emulation-mdx": () => import("./../../../src/pages/emulation.mdx" /* webpackChunkName: "component---src-pages-emulation-mdx" */),
  "component---src-pages-high-scores-tsx": () => import("./../../../src/pages/high-scores.tsx" /* webpackChunkName: "component---src-pages-high-scores-tsx" */),
  "component---src-pages-history-mdx": () => import("./../../../src/pages/history.mdx" /* webpackChunkName: "component---src-pages-history-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interviews-adrian-stephens-mdx": () => import("./../../../src/pages/interviews/adrian-stephens.mdx" /* webpackChunkName: "component---src-pages-interviews-adrian-stephens-mdx" */),
  "component---src-pages-interviews-gary-partis-mdx": () => import("./../../../src/pages/interviews/gary-partis.mdx" /* webpackChunkName: "component---src-pages-interviews-gary-partis-mdx" */),
  "component---src-pages-interviews-jonathan-griffiths-mdx": () => import("./../../../src/pages/interviews/jonathan-griffiths.mdx" /* webpackChunkName: "component---src-pages-interviews-jonathan-griffiths-mdx" */),
  "component---src-pages-interviews-mad-hatter-mdx": () => import("./../../../src/pages/interviews/mad-hatter.mdx" /* webpackChunkName: "component---src-pages-interviews-mad-hatter-mdx" */),
  "component---src-pages-interviews-nick-pelling-mdx": () => import("./../../../src/pages/interviews/nick-pelling.mdx" /* webpackChunkName: "component---src-pages-interviews-nick-pelling-mdx" */),
  "component---src-pages-interviews-peter-scott-mdx": () => import("./../../../src/pages/interviews/peter-scott.mdx" /* webpackChunkName: "component---src-pages-interviews-peter-scott-mdx" */),
  "component---src-pages-interviews-richard-hanson-mdx": () => import("./../../../src/pages/interviews/richard-hanson.mdx" /* webpackChunkName: "component---src-pages-interviews-richard-hanson-mdx" */),
  "component---src-pages-interviews-steve-hanson-mdx": () => import("./../../../src/pages/interviews/steve-hanson.mdx" /* webpackChunkName: "component---src-pages-interviews-steve-hanson-mdx" */),
  "component---src-pages-links-mdx": () => import("./../../../src/pages/links.mdx" /* webpackChunkName: "component---src-pages-links-mdx" */),
  "component---src-pages-lost-games-mdx": () => import("./../../../src/pages/lost-games.mdx" /* webpackChunkName: "component---src-pages-lost-games-mdx" */),
  "component---src-pages-news-mdx": () => import("./../../../src/pages/news.mdx" /* webpackChunkName: "component---src-pages-news-mdx" */),
  "component---src-pages-welcome-mdx": () => import("./../../../src/pages/welcome.mdx" /* webpackChunkName: "component---src-pages-welcome-mdx" */),
  "component---src-templates-company-tsx": () => import("./../../../src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */)
}

